<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">{{ $t("UnauthorizedAction") }}</h2>
        <p class="mb-2">
          {{ $t("OopsMessage") }}
        </p>
        <p class="mb-2">
          <b-button
            variant="primary"
            @click="
              () => {
                $router.go();
              }
            "
            >{{ $t("Refresh") }}</b-button
          >
        </p>
        <!-- image -->
        <ErrorImg />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from "bootstrap-vue";
import DefaultLogo from "@/views/Common/DefaultLogo.vue";
import store from "@/store/index";
import ErrorImg from "@/assets/images/pages/error.svg";
export default {
  components: {
    DefaultLogo,
    BLink,
    BButton,
    BImg,
    ErrorImg
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/error.svg")
    };
  },
  computed: {}
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>

<template>
  <div
    class="customizer unit-filter-dashboard"
    :class="{ open: isCustomizerOpen }"
  >
    <!-- Toggler -->
    <b-link
      class="customizer-toggle d-flex align-items-center justify-content-center"
      @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <slidersIcon />
      <!-- <feather-icon icon="FilterIcon" size="15" /> -->
    </b-link>
    <div class="close-btn">
      <feather-icon
        icon="XIcon"
        size="24"
        class="cursor-pointer"
        @click="isCustomizerOpen = !isCustomizerOpen"
      />
    </div>

    <div class="filter-tab">
      <b-tabs>
        <b-tab :title="$t('Widget.tabFiltersTitle')" active>
          <!-- <div
            class="customizer-section d-flex justify-content-between align-items-center top-control"
          >
            <div>
              <h4 class="m-1">
                {{ $t("ConsumerDashboard.UnitFilterAndGroupFilter") }}
              </h4>
             
            </div>
            <feather-icon
              icon="XIcon"
              size="18"
              class="cursor-pointer"
              @click="isCustomizerOpen = !isCustomizerOpen"
            />
          </div> -->

          <div class="dashboard-filter-scroll">
            <div
              class="customizer-section auto-search-padding-0 mt-2"
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.CONSUMER_DASH_SUB_ACC,
                  subject: constants.PERMISSIONS_MODEL.WIDGETS
                })
              "
            >
              <AccountSearch
                v-model="accountId"
                perfectScrollbarSettings
                :placeholderName="$t('device.AllocatedAccount')"
                :accountsList="[]"
              />
            </div>
            <div class="customizer-section unit-search-comp-ui">
              <NotificationUnitsSelection
                :isEditable="isEditable"
                :selectedUnitsData="selectedUnitsData"
                :accountId="accountId"
                :selectedUnitByUser="selectedUnitByUser"
                :isClear="isClear"
                :unitFun="unitFun"
                :componentKey="'dashboard'"
                :getUnitLookupCount="getUnitLookupCount"
                :totalLookUpCount="totalLookUpCount"
                :removedSelectedItem="removedSelectedItem"
                :isAllUnitEnabled="isAllUnitEnabled"
              ></NotificationUnitsSelection>
              <!-- <DashboardUnitFilter :setFilter="setFilter"  :accountId="accountId"></DashboardUnitFilter> -->
            </div>
          </div>
          <!-- </vue-perfect-scrollbar> -->
          <div class="apply-btn-outer">
            <!-- <b-img :src="slidersIcon" alt="Sliders Icon" /> -->

            <b-button
              @click="apply"
              :disabled="isValid()"
              variant="primary"
              class="btn-tour-next"
            >
              {{ $t("Apply") }}
            </b-button>
          </div>
        </b-tab>
        <b-tab :title="$t('Widget.tabTitle')">
          <div>
            <DashboardWidgets @closeSlider="closeSlider"></DashboardWidgets>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import {
  BFormRadioGroup,
  BFormGroup,
  BLink,
  BButton,
  BImg,
  BCardText,
  BTabs,
  BTab
} from "bootstrap-vue";
import AccountSearch from "@/views/Unit/Unit-V2/AccountSearch.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import useAppCustomizer from "@/@core/layouts/components/app-customizer/useAppCustomizer";
// import DashboardUnitFilter from "@/views/Dashboard/Consumer/DashboardUnitFilter.vue";
import EventBus from "@/EventBus";
import NotificationUnitsSelection from "@/views/Notification/NotificationUnitsSelection.vue";
import slidersIcon from "@/assets/images/icons/slidersIcon.svg";
import DashboardWidgets from "./DashboardWidgets.vue";
export default {
  components: {
    // BSV
    AccountSearch,
    BFormRadioGroup,
    useAppCustomizer,
    BFormGroup,
    VuePerfectScrollbar,
    BLink,
    // DashboardUnitFilter,
    BButton,
    NotificationUnitsSelection,
    BImg,
    slidersIcon,
    BCardText,
    BTabs,
    BTab,

    DashboardWidgets
  },

  setup() {
    const {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen

      // Skin
    } = useAppCustomizer();

    // Perfect Scrollbar
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false
    };

    return {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      perfectScrollbarSettings
    };
  },
  data() {
    return {
      showModal: false,
      isAllUnit: true,
      accountId: localStorage.getItem("USER_ACCOUNT_ID"),
      isEditable: true,
      selectedUnitsData: [],
      totalLookUpCount: 0,
      removedSelectedItem: {},
      selectedUnselectedItems: {
        unit: { selected: [], unselected: [] },
        group: { selected: [], unselected: [] },
        isAllUnit: true
      },
      selectedUnselectedFinalItems: {
        unit: { selected: [], unselected: [] },
        group: { selected: [], unselected: [] }
      },
      selectedUnitByUser: [],
      isClear: false,
      selectedUnitsData: [],
      notification: {
        units: {}
      },
      isApplyDisabledFlag: false
    };
  },
  created() {
    EventBus.$on("check-is-have-unit-or-group", this.isApplyDisabled);
  },
  mounted() {
    this.accountId = localStorage.getItem("USER_ACCOUNT_ID");
  },
  methods: {
    openModal(widget) {
      this.showModal = !this.showModal;
    },
    isApplyDisabled(flag) {
      this.isApplyDisabledFlag = flag;
    },
    apply() {
      this.$store.commit("dashboard/SET_DASH_GLOBAL_FILTER", {
        filters: {
          units: this.selectedUnselectedItems.unit
            ? JSON.parse(
                JSON.stringify(this.selectedUnselectedItems.unit.selected)
              )
            : [],
          unitGroups: this.selectedUnselectedItems.group
            ? JSON.parse(
                JSON.stringify(this.selectedUnselectedItems.group.selected)
              )
            : [],
          isAllUnit:
            this.selectedUnselectedItems.isAllUnit === false ? false : true
        },
        accountId: this.accountId
      });
      EventBus.$emit(
        "add-global-filter",
        this.selectedUnselectedItems,
        this.accountId
      );

      this.isCustomizerOpen = !this.isCustomizerOpen;
    },
    closeSlider() {
      this.isCustomizerOpen = !this.isCustomizerOpen;
    },
    isValid() {
      if (
        !this.isApplyDisabledFlag &&
        this.selectedUnselectedItems &&
        (this.selectedUnselectedItems.unit.selected.length ||
          this.selectedUnselectedItems.group.selected.length ||
          this.selectedUnselectedItems.isAllUnit)
      ) {
        return false;
      } else {
        return true;
      }
    },
    setFilter(filter) {
      this.filters = filter;
    },
    unitFun(data, isAlreadySave, flag) {
      // if (isAlreadySave) {
      //   this.selectedUnselectedItems[isAlreadySave] = flag;
      // }
      this.selectedUnselectedItems.group.selected = [];
      this.selectedUnselectedItems.unit.selected = [];
      data.selected.forEach((element) => {
        if (element && element.source === "GROUP") {
          if (
            !this.selectedUnselectedItems.group.selected.includes(element.id)
          ) {
            this.selectedUnselectedItems.group.selected.push(element.id);
          }
        } else if (element && element.source === "UNIT") {
          if (
            !this.selectedUnselectedItems.unit.selected.includes(element.id)
          ) {
            this.selectedUnselectedItems.unit.selected.push(element.id);
          }
        }
      });

      // if (!isAlreadySave) {
      this.notification.units = [
        {
          type: "GROUP",
          items: this.selectedUnselectedItems.group
        },
        {
          type: "UNIT",
          items: this.selectedUnselectedItems.unit
        }
      ];
      // }
      this.selectedUnselectedItems.isAllUnit = flag;
      //this.selectedUnitByUser = this.notification.units;
      this.selectedUnitsData = data.selected;
    },
    getUnitLookupCount(count) {
      this.totalLookUpCount = count;
    },
    isAllUnitEnabled() {}
  },
  beforeDestroy() {
    EventBus.$off("check-is-have-unit-or-group", this.isApplyDisabled);
  }
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.customizer-section {
  padding: 0.5rem;
  // border-bottom: 1px solid #ebe9f1;
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.ps-customizer-area {
  height: calc(100% - 110px);
}
</style>
<style lang="scss">
.filter-tab .nav-item {
  width: 50%;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
}
.filter-tab .nav-item .active {
  width: 100%;
  font-weight: 800;
  border-bottom: 2px solid var(--primary);
}
.filter-tab #__BVID__164__BV_tab_controls_ {
  width: 100%;
  margin-bottom: 0px;
  margin-top: 20px;
}
.filter-tab #__BVID__164__BV_tab_container_ {
  width: 100% !important;
}

.unit-filter-dashboard {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}
#__BVID__185___BV_modal_outer_ {
  z-index: 9999 !important;
}
.unit-filter-tab {
  width: 30%;
  height: 50vh;
}
.graph-tab {
  width: 70%;
  height: 80vh;

  background-color: #16161d;
}
.main-popup {
  display: flex;
}
.close-btn {
  position: absolute;
  z-index: 9;
  right: 5px;
  top: 5px;
}
.dashboard-filter-scroll {
  min-height: calc(100vh - 120px);
  max-height: calc(100vh - 120px);
  .unit-icon-card {
    min-height: calc(84vh - 175px);
    min-height: calc(84vh - 175px);
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.Unit-dashboard-scroll {
  .userlist-table {
    max-height: calc(84vh - 250px) !important;
  }
}
</style>

<template>
  <div>
    <div class="main-filter-tab" v-if="show">
      <div v-for="(widget, index) in 8" :key="index" class="widgets">
        <b-skeleton-img height="20vh"></b-skeleton-img>
      </div>
    </div>

    <div class="main-filter-tab" v-else>
      <div
        v-for="(widget, index) in widgets"
        :key="index"
        class="widgets widget"
        @click="openModal(widget)"
      >
        <div class="widgets-icon">
          <!-- <component :is="widget.icon" /> -->
          <b-img :src="widget.icon" />
        </div>
        <div class="widgets-name">
          <span>{{ widget.title }}</span>
        </div>
      </div>

      <DashboardPreviewModal
        :widget="selectedWidget"
        :selectedWidgetType="selectedWidgetType"
        :isEdit="false"
        :tempId="tempId"
        @closeSlider="closeSlider"
      ></DashboardPreviewModal>
    </div>
  </div>
</template>

<script>
import { BImg, BCardText, BSkeletonImg } from "bootstrap-vue";
import DashboardPreviewModal from "@/views/Dashboard/Consumer/DashboardPreviewModal.vue";
import ReportService from "@/libs/api/report-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BImg,
    BCardText,
    BSkeletonImg,
    DashboardPreviewModal
  },

  setup() {},
  data() {
    return {
      widgets: [],
      showModal: "",
      totalWidgets: 0,
      selectedWidget: {},
      tempId: 10,
      selectedWidgetType: "",
      show: true
    };
  },
  created() {},
  mounted() {
    this.accountId = localStorage.getItem("USER_ACCOUNT_ID");
    this.getAllWidgetsList();
  },
  methods: {
    openModal(widget) {
      this.selectedWidget = widget;
      this.selectedWidget.id = null;
      this.$bvModal.show("modal-sm-consumer-dash");
      this.tempId = Math.floor(Math.random() * 100) + 1;
      this.selectedWidgetType = this.selectedWidget.type;
      // this.$emit("selectedWidget", widget);
    },
    async getAllWidgetsList() {
      try {
        this.show = true;

        this.socketData = {};
        let response = await new ReportService().getAllWidgets({});
        this.show = false;
        if (response && response.data) {
          this.widgets = response.data.list || [];
          this.totalWidgets = response.data.pagination.total_records;
          if (!this.widgets.length) {
            return;
          }
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    closeSlider() {
      this.$emit("closeSlider", true);
    }
  },
  beforeDestroy() {}
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.customizer-section {
  padding: 0.5rem;
  // border-bottom: 1px solid #ebe9f1;
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.ps-customizer-area {
  height: calc(100% - 110px);
}
</style>
<style lang="scss">
.filter-tab .nav-item {
  width: 50%;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
}
.filter-tab .nav-item .active {
  width: 100%;
  font-weight: 800;
  border-bottom: 2px solid var(--primary);
}
.filter-tab #__BVID__164__BV_tab_controls_ {
  width: 100%;
  margin-bottom: 0px;
  margin-top: 20px;
}
.filter-tab #__BVID__164__BV_tab_container_ {
  width: 100% !important;
}
.widgets {
  width: 46% !important;
  height: 22vh;
  background: #f6f6f6;
  border: 1px #e2e2e2 solid;
  margin: 2%;
  cursor: pointer;
}
.main-filter-tab {
  margin-left: 5px;
  margin-right: 5px;
  width: 100%;

  overflow-y: scroll;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;

  overflow-x: scroll;
}
.widgets-icon {
  max-height: 68%;
  min-height: 68%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.widgets-name {
  color: #16161d;
  font-size: 13px;
  font-weight: 800;
  line-height: 20px;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
  align-items: start;
  min-height: 25%;
  max-height: 25%;
  text-align: center;
}

.dark-layout .widgets span {
  color: #d0d2d6;
}
.dark-layout .widgets {
  background-color: #161d30;
  border: 1px #3f3f3f solid;
}
.unit-filter-dashboard {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}
#__BVID__185___BV_modal_outer_ {
  z-index: 9999 !important;
}
.unit-filter-tab {
  width: 30%;
  height: 50vh;
}
.graph-tab {
  width: 70%;
  height: 80vh;

  background-color: #16161d;
}
.main-popup {
  display: flex;
}
.main-filter-tab {
  overflow: auto;
  height: auto;
  max-height: calc(100vh - 70px);
}
.widget {
  &:hover {
    border: 2px solid var(--primary);
  }
}
</style>
